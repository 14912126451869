<template>
  <div class="low">
    <div
      v-for="(item, index) in chartData.low"
      :key="index"
      :class="['relation', `relation_${item.relation}`]"
      :style="{ left: getLowXaxis(index), top: getLowYaxis(index) }"
    >
      {{ item.name }}
    </div>
    <div class="middle">
      <div
        v-for="(item, index) in chartData.middle"
        :key="index"
        :class="['relation', `relation_${item.relation}`]"
        :style="{ left: getMiddleXaxis(index), top: getMiddleYaxis(index) }"
      >
        {{ item.name }}
      </div>
      <div class="high">
        <div
          v-for="(item, index) in chartData.high"
          :key="index"
          :class="['relation', `relation_${item.relation}`]"
          :style="{ left: getHighXaxis(index), top: getHighYaxis(index) }"
        >
          {{ item.name }}
        </div>
        <div class="profile">
          <!-- <img class="logo" :src="profileUrl" /> -->
          <p>{{ profileName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RelationCircle',
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    profileUrl: {
      type: String,
      default: '',
    },
    profileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      capital: {
        high: [160, 160],
        middle: [265, 265],
      },
    };
  },
  methods: {
    /* 
        利用根据三角形的正弦、余弦，得到圆上每个点的坐标
        圆心坐标(a,b), r半径
        X坐标 = a + Math.sin(2*Math.PI / 360) * r  
        Y坐标 = b + Math.cos(2*Math.PI / 360) * r 
    */

    getHighXaxis(index) {
      const count = this.chartData.high.length;
      const angle = 360 / count;
      return `${128 + Math.sin(((2 * Math.PI) / 360) * angle * (index + 1)) * 110}px`;
    },
    getHighYaxis(index) {
      const count = this.chartData.high.length;
      const angle = 360 / count;
      return `${128 + Math.cos(((2 * Math.PI) / 360) * angle * (index + 1)) * 110}px`;
    },
    getMiddleXaxis(index) {
      const count = this.chartData.middle.length;
      const angle = 360 / count;
      return `${233 + Math.sin(((2 * Math.PI) / 360) * angle * (index + 1)) * 210}px`;
    },
    getMiddleYaxis(index) {
      const count = this.chartData.middle.length;
      const angle = 360 / count;
      return `${233 + Math.cos(((2 * Math.PI) / 360) * angle * (index + 1)) * 210}px`;
    },
    getLowXaxis(index) {
      const count = this.chartData.low.length;
      const angle = 360 / count;
      return `${288 + Math.sin(((2 * Math.PI) / 360) * angle * (index + 1)) * 316}px`;
    },
    getLowYaxis(index) {
      const count = this.chartData.low.length;
      const angle = 360 / count;
      return `${288 + Math.cos(((2 * Math.PI) / 360) * angle * (index + 1)) * 316}px`;
    },
  },
};
</script>
<style scoped>
.profile {
  width: 120px;
  height: 120px;
  position: relative;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 100%;

  background: #0069fa;
  box-shadow: 0px 8px 20px rgba(0, 105, 250, 0.1);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}
.logo {
  width: 80px;
  height: 80px;
}
.low {
  position: relative;
  width: 640px;
  height: 640px;
  box-sizing: border-box;
  border-radius: 100%;
}

.high {
  position: relative;
  top: calc(50% - 160px);
  left: calc(50% - 160px);
  width: 320px;
  height: 320px;
  box-sizing: border-box;
  border-radius: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 38.28%, #f6f7fb 100%);
}

.middle {
  position: relative;
  top: calc(50% - 265px);
  left: calc(50% - 265px);
  width: 530px;
  height: 530px;
  box-sizing: border-box;
  border-radius: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 60.86%, #f6f7fb 100%);
}

.relation {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  font-size: 14px;
  font-weight: 500;
}

.relation_teacher {
  background: #fff4eb;
  color: #d9450f;
}

.relation_student {
  background: #fffce6;
  color: #d99100;
}

.relation_coperation {
  background: #f7f0ff;
  color: #633dcc;
}

.relation_fellow {
  background: #dff7ec;
  color: #00916b;
}

.relation_classmate {
  background: #ebf8ff;
  color: #0f6dd9;
}
</style>
