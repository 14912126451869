<!--  -->
<template>
  <div class="container">
    <div class="detail-top">
      <div class="back" @click="handleBack">
        <span></span>
        <p>返回</p>
      </div>
      <div class="title">360画像详情</div>
      <el-button class="btn" @click="handleToDetail(info.name, $route.query.id, 1)">
        管理主数据
      </el-button>
    </div>
    <el-row>
      <div class="introduction box">
        <div class="avatar">
          <img v-if="info.gender === 2" :src="manImg" />
          <img v-if="info.gender === 1" :src="womanImg" />
        </div>
        <div class="info">
          <div class="info-name">
            <div class="name">{{ info.name }}</div>
            <div class="position">{{ showLabel(info.academic_title, 'academicTitleOptions') }}</div>
            <div class="position">
              {{ showLabel(info.professional_title, 'professionalTitleOptins') }}
            </div>
          </div>
          <div class="info-hos info-name">
            <div class="position1">{{ info.hco_name }}</div>
            <div class="position" v-if="standard_dept.length">{{ standard_dept.join('/') }}</div>
            <div class="position" v-else>{{ '-' }}</div>
          </div>
          <el-col :span="20">
            <div id="info-tags" v-if="tagData.length">
              <el-popover
                class="item"
                v-for="item in tagData"
                :key="item.id"
                placement="bottom"
                trigger="click"
                width="240"
              >
                <div class="detail-item">
                  <span class="title">分类：</span>
                  <span class="data">{{ item.type2 }}</span>
                </div>
                <div class="detail-item">
                  <span class="title">创建人：</span>
                  <span class="data">{{ item.create_user }}</span>
                </div>
                <div class="detail-item">
                  <span class="title">更新时间：</span>
                  <span class="data">{{ item.update_time }}</span>
                </div>
                <div class="detail-item">
                  <span class="title">生成方式：</span>
                  <span class="data">
                    {{
                      item.create_way === 1
                        ? '规则生成'
                        : item.create_way === 2
                        ? '算法生成'
                        : '人工录入'
                    }}
                  </span>
                </div>
                <div class="detail-item">
                  <span class="title">更新方式：</span>
                  <span class="data">{{ item.update_way | formatTagUpdateWay }}</span>
                </div>
                <div>
                  <el-button type="text" @click="handleGoTag(item)" v-if="systemEnv === '1'">
                    查看标签详情
                  </el-button>
                </div>
                <el-button slot="reference" class="tag_btn_show">
                  <span class="tag_contents">
                    <el-tooltip class="item" effect="dark" placement="top" popper-class="tag_tip">
                      <div slot="content">
                        {{ item.name }}：{{ item.values.map(v => v.name).join(',') }}
                      </div>
                      <el-button>
                        {{ item.name }}：{{ item.values.map(v => v.name).join(',') }}
                      </el-button>
                    </el-tooltip>
                  </span>
                </el-button>
              </el-popover>

              <div v-if="show">
                <div v-if="showBtnZK && showDivZK" class="expansion" @click="restoreTags">展开</div>
                <div v-else class="up" @click="cuttingTags">收起</div>
              </div>
            </div>
          </el-col>
          <div class="description" v-if="info.hcp_description !== ''">
            <span>简介：</span>
            <div class="content">
              <Ellipsis
                :rows="2"
                :content="info.hcp_description"
                @click-btn="handleExpand(false)"
                v-if="ellipsis && info.hcp_description !== ''"
              ></Ellipsis>
              <div class="ellipsis-content" v-else>
                {{ info.hcp_description }}
                <el-button
                  v-if="info.hcp_description !== ''"
                  type="text"
                  class="btn"
                  @click.stop="handleExpand(true)"
                >
                  折叠
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-row>

    <!-- 医生画像图表 -->
    <div class="doctor-img-content box">
      <el-tabs v-model="activeName" @tab-click="handleClickTab" class="tabs">
        <el-tab-pane label="关系网络" name="relation_network"></el-tab-pane>
        <el-tab-pane label="影响力" name="influence"></el-tab-pane>
        <el-tab-pane label="学术动态" name="academic_trend"></el-tab-pane>
      </el-tabs>
      <div class="doctor-img-content-charts">
        <!-- 关系网络 -->
        <div
          class="relation-network-chart-wrap"
          v-show="activeName === 'relation_network' && !requestData"
        >
          <div class="relation-network-chart">
            <div class="chart-desc">
              <p class="chart-desc-teacher">老师</p>
              <p class="chart-desc-student">学生</p>
              <p class="chart-desc-colleague">同事</p>
              <p class="chart-desc-classmate">同学</p>
              <p class="chart-desc-cooperation">合作</p>
            </div>
            <div class="chart-content">
              <relation-circle
                :chart-data="relationChartData"
                :profile-name="info.name"
              ></relation-circle>
            </div>
          </div>

          <div class="relation-network-table">
            <!-- 值名称搜索关键词 -->
            <div v-if="filterList.length > 0" class="table-filter-wrap">
              <div class="content_keyword_item" v-for="(item, index) in filterList" :key="index">
                {{ getFlitersTitle(item.name) }}: {{ item.label }}
                <i class="el-icon-close del" @click="handleDelKeyWords(item.name)"></i>
              </div>
              <el-button v-if="filterList.length > 0" type="text" @click="deleteAllFilter">
                重置
              </el-button>
            </div>

            <eyao-table
              v-show="!isQeqTable"
              ref="table"
              :table-data="tableData"
              :total-num="totalNum"
              :select-page-size="10"
              :layout="'total, prev, pager, next'"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
              <el-table-column
                v-for="column in configList"
                :key="`${$route.name}-relation-network-${column.property}`"
                :label="column.title"
                :prop="column.property"
                :width="column.width"
              >
                <template slot="header" slot-scope="scope">
                  <!-- 表头 -->
                  <eyao-table-search-header
                    v-if="column.showIcon"
                    :form-type="column.showIcon"
                    :property="column.property"
                    :title="column.title"
                    :scope="scope"
                    :options="valueOpts[column.property]"
                    @search="searchOpts"
                    :is-multiple="column.isMultiple"
                  ></eyao-table-search-header>
                  <!-- 无icon -->
                  <template v-else>{{ column.title }}</template>
                </template>
                <template slot-scope="scope">
                  <template v-if="column.property === 'hcp_name'">
                    <span
                      :class="{ 'relation-hcpName': scope.row.hcp_id !== '' }"
                      @click="handleToDetail(scope.row.hcp_name, scope.row.hcp_id, 2)"
                    >
                      {{ scope.row[column.property] }}
                    </span>
                    <!-- <span :class="{ 'relation-hcpName': scope.row.hcp_id !== '' }">
                      {{ scope.row[column.property] }}
                    </span> -->
                  </template>
                  <template v-else-if="column.property === 'relationship'">
                    <p
                      class="chart-desc-teacher data-status"
                      v-if="
                        scope.row.relationship === '老师' ||
                          scope.row.relationship === '（师从）老师'
                      "
                    >
                      老师
                    </p>
                    <p
                      class="chart-desc-student data-status"
                      v-if="
                        scope.row.relationship === '学生' ||
                          scope.row.relationship === '（辅导）学生'
                      "
                    >
                      学生
                    </p>
                    <p
                      class="chart-desc-colleague data-status"
                      v-if="scope.row.relationship === '同事'"
                    >
                      同事
                    </p>
                    <p
                      class="chart-desc-classmate data-status"
                      v-if="scope.row.relationship === '同学'"
                    >
                      同学
                    </p>
                    <p
                      class="chart-desc-cooperation data-status"
                      v-if="scope.row.relationship === '合作'"
                    >
                      合作
                    </p>
                  </template>
                </template>
              </el-table-column>
              <template v-slot:empty>
                <div class="search-no-data" v-if="totalNum === 0 && !isQeqTable">
                  <img
                    src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                    alt="未查询到相关数据"
                  />
                  未查询到相关数据
                </div>
              </template>
            </eyao-table>

            <!-- <div v-if="totalNum === 0 && !isQeqTable" class="search-no-data">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div> -->

            <div class="listLoading" v-if="isQeqTable">
              <img src="@/assets/loading.gif" alt="loading" />
            </div>
          </div>
        </div>
        <!-- 影响力 -->
        <div class="influence-chart-wrap" v-show="activeName === 'influence' && !requestData">
          <div id="influence-chart"></div>

          <div class="influence-data-list">
            <div class="influence-statistics">
              <div class="total-score commonSty">
                总分
                <p>{{ influenceScore.score }}</p>
              </div>
              <div class="city-benchmark commonSty">
                KOL平均分
                <p>{{ influenceScore.kol_score }}</p>
              </div>
              <div class="dept-benchmark commonSty">
                行业平均分
                <p>{{ influenceScore.industry_score }}</p>
              </div>
            </div>
            <eyao-table class="influence-table" :table-data="tableData" :pagination-show="false">
              <el-table-column
                v-for="column in configList"
                :key="`${$route.name}-influence-${column.property}`"
                :label="column.title"
                :prop="column.property"
                :width="column.width"
              >
                <template slot-scope="scope">
                  {{ scope.row[column.property] }}
                </template>
              </el-table-column>
            </eyao-table>
          </div>
        </div>
        <!-- 学术动态 -->
        <div
          class="academic-trend-chart-wrap"
          v-show="activeName === 'academic_trend' && !requestData"
        >
          <div class="academic-trend-select-warp">
            <div class="academic-trend-select">
              <div
                v-for="(item, index) in selectType"
                :key="index"
                :class="{ 'select-active': selectIndex === index }"
                @click="handleSelectType(index)"
              >
                {{ item }}
              </div>
            </div>
            <el-tooltip
              effect="dark"
              content="中文学术为被知网等国内数据库收录文献，外文学术为被pubmed等外文数据库收录文献"
              placement="top"
            >
              <img src="./img/trips.svg" alt="" />
            </el-tooltip>
          </div>
          <div class="academic-trend-statistic">
            <div class="total-num commonSty">
              <p>
                <img :src="totalSvg" alt="" />
                <span>合计 (篇)</span>
              </p>
              <p>
                <i>{{ academicStatistics.article_total }}</i>
                <span>(第一作者: {{ getFirstPercent }})</span>
              </p>
            </div>
            <div class="periodical commonSty">
              <p>
                <img :src="courseSvg" alt="" />
                <span>期刊 (篇)</span>
              </p>
              <p>
                <i>{{ academicStatistics.periodical_total }}</i>
                <span>(核心期刊比率: {{ getCorePeriodical }})</span>
              </p>
            </div>
            <div class="meeting commonSty">
              <p>
                <img :src="meetingSvg" alt="" />
                <span>会议 (篇)</span>
              </p>
              <p>
                <i>{{ academicStatistics.meeting_total }}</i>
              </p>
            </div>
            <div class="other-newspaper commonSty">
              <p>
                <img :src="careerSvg" alt="" />
                <span>报刊及其他 (篇)</span>
              </p>
              <p>
                <i>{{ academicStatistics.other_total }}</i>
              </p>
            </div>
          </div>
          <div class="academic-trend-opt">
            <el-select v-model="academicType" class="select-type" @change="changeAcademicType">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div class="select-date">
              <p
                v-for="(item, index) in dateTabList"
                :key="index"
                :class="{ 'is-active': dateTabIndex === index }"
                @click="handleDateChange(index)"
              >
                {{ item }}
              </p>
            </div>
          </div>

          <div v-show="!isQeqTable">
            <div id="academic-chart"></div>

            <div class="academic-table-content">
              <!-- 表格筛选区域 -->
              <div v-if="filterList.length > 0" class="table-filter-wrap">
                <div v-for="(item, index) in filterList" :key="index" class="content_keyword_item">
                  {{ getFlitersTitle(item.name) }}: {{ item.label }}
                  <i class="el-icon-close" @click="handleDelKeyWords(item.name)"></i>
                </div>
                <el-button
                  v-if="filterList.length"
                  class="datalist-title-button"
                  type="text"
                  @click="deleteAllFilter"
                >
                  重置
                </el-button>
              </div>
              <eyao-table
                v-show="!isQeqTable"
                class="academic-table"
                :table-data="tableData"
                :total-num="totalNum"
                :select-page-size="10"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :default-sort="{ prop: 'publish_time' }"
              >
                <el-table-column
                  v-for="column in configList"
                  :key="`${$route.name}-academic-${column.property}`"
                  :label="column.title"
                  :prop="column.property"
                  :width="column.width"
                  :min-width="column['min-width']"
                  :sortable="column.sort"
                >
                  <template slot="header" slot-scope="scope">
                    <!-- 表头 -->
                    <eyao-table-search-header
                      v-if="column.showIcon"
                      :form-type="column.showIcon"
                      :property="column.property"
                      :title="column.title"
                      :scope="scope"
                      :options="valueOpts[column.property]"
                      @search="searchOpts"
                    ></eyao-table-search-header>
                    <!-- 无icon -->
                    <template v-else>{{ column.title }}</template>
                  </template>

                  <template slot-scope="scope">
                    <!-- 涉及时间字段，format值 -->
                    <template v-if="column.format">
                      {{ scope.row[column.property] | getFormatTime }}
                    </template>
                    <!-- 作者个数超过3个...显示并hover展示所有 -->
                    <template v-else-if="column.property === 'authors'">
                      <el-popover
                        placement="top-start"
                        width="160"
                        trigger="hover"
                        :content="formatOblique(scope.row.authors)"
                      >
                        <span slot="reference" :style="{ cursor: 'pointer' }">
                          {{ scope.row[column.property] | formatEllipsis }}
                        </span>
                      </el-popover>
                    </template>
                    <!-- 是否第一作者 -->
                    <template v-else-if="column.property === 'author_index'">
                      {{ scope.row[column.property] | isFirstAuthor }}
                    </template>
                    <!-- 是否核心 -->
                    <template v-else-if="column.property === 'periodical_type'">
                      {{ scope.row[column.property] | formatIsCore }}
                    </template>
                    <template v-else>{{ scope.row[column.property] }}</template>
                  </template>
                </el-table-column>
                <template v-slot:empty>
                  <div class="search-no-data2" v-if="totalNum === 0 && !isQeqTable && !isQeqTable2">
                    <img
                      src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                      alt="未查询到相关数据"
                    />
                    未查询到相关数据
                  </div>
                </template>
              </eyao-table>
              <!-- <div v-if="totalNum === 0 && !isQeqTable && !isQeqTable2" class="search-no-data2">
                <img
                  src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                  alt="未查询到相关数据"
                />
                未查询到相关数据
              </div> -->
              <div class="listLoading" v-if="isQeqTable2">
                <img src="@/assets/loading.gif" alt="loading" />
              </div>
            </div>
          </div>
          <div class="listLoading" v-if="isQeqTable">
            <img src="@/assets/loading.gif" alt="loading" />
          </div>
        </div>

        <div class="listLoading" v-if="requestData">
          <img src="@/assets/loading.gif" alt="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import relationCircle from '@/components/relation-circle';
import relationNetConfig from './config/relation_network';
import influenceConfig from './config/influence';
import academicConfig from './config/academic';
import totalSvg from '@/assets/svg/total.svg';
import courseSvg from '@/assets/svg/course.svg';
import meetingSvg from '@/assets/svg/meeting.svg';
import careerSvg from '@/assets/svg/career.svg';
import {
  // GetMainDataDetail,
  GetDictOptionList,
} from '@/api/data';
// import { GetTagInfosByValues } from '@/api/tag';
import ellipsis from 'vue-overflow-ellipsis';
import 'vue-overflow-ellipsis/dist/index.css';
import {
  SearchHcpSocialRelations,
  SearchHcpInfluence,
  SearchHcpArticle,
  SearchArticleYears,
  GetKolDetail,
} from '@/api/doctor-image';
import womanImg from '@/assets/img/woman.png';
import manImg from '@/assets/img/man.png';
import { getDate, dateFormat } from '@/utils/util';

export default {
  name: 'DcotorDetail',
  components: { relationCircle, eyaoTable, eyaoTableSearchHeader, Ellipsis: ellipsis },
  filters: {
    formatTagUpdateWay(val) {
      if (val === 1) {
        return '手动更新';
      }
      if (val === 2) {
        return '定时更新';
      }
      return '';
    },
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd');
      }
      return '';
    },
    isFirstAuthor(value) {
      if (value === '1') return '是';
      return '否';
    },
    formatEllipsis(value) {
      if (!value) return '';
      const valArr = value.split(';');
      let retArr = [];

      if (valArr.length > 3) {
        retArr = valArr.slice(0, 3);
        return `${retArr.join('/')}...`;
      }
      return `${valArr.slice(0, 3).join('/')}`;
    },
    formatIsCore(val) {
      if (val === '') {
        return '否';
      }
      return '是';
    },
  },
  data() {
    return {
      selectType: ['中文学术', '外文学术'],
      selectIndex: 0,

      cache: ['DoctorImage'],
      ellipsis: true,
      tagDataOld: [],
      tagData: [],
      options: {
        professionalTitleOptins: [],
        academicTitleOptions: [],
      },
      info: {},
      standard_dept: [],
      show: false,
      showBtnZK: false,
      showDivZK: false,
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      // 图表tab
      activeName: 'relation_network',
      requestData: true,
      isQeqTable: true,
      isQeqTable2: true,
      // 表格数据
      tableData: [],
      totalNum: 0,
      configList: [],
      configLabel: [],
      currentDataPage: 1,
      pageDataSize: 10,
      filterObject: {},
      filterList: [],
      relationChartData: {},
      // 影响力图表
      influenceChart: null,
      influenceScore: { score: 0, kol_score: 0, industry_score: 0 },
      // 学术动态图表
      academicChart: null,
      academicStatistics: {
        article_total: 0,
        first_total: 0,
        core_periodical: 0,
        periodical_total: 0,
        meeting_total: 0,
        other_total: 0,
      },
      academicType: '0',
      typeOptions: [
        { label: '所有', value: '0' },
        { label: '期刊', value: '1' },
        { label: '会议', value: '2' },
        { label: '报刊与其它', value: '3' },
      ],
      dateTabList: ['全部', '近三年', '近五年'],
      dateTabIndex: 0,
      valueOpts: {
        relationship: [
          { label: '老师', value: 'teacher' },
          { label: '学生', value: 'student' },
          { label: '同事', value: 'colleague' },
          { label: '同学', value: 'classmate' },
          { label: '合作', value: 'cooperation' },
        ],
        author_index: [
          { label: '是', value: '1' },
          { label: '否', value: '2' },
        ],
        periodical_type: [
          { label: '是', value: 1 },
          { label: '否', value: 2 },
        ],
      },
      totalSvg,
      courseSvg,
      meetingSvg,
      careerSvg,
      womanImg,
      manImg,
    };
  },
  computed: {
    // 第一作者比例
    getFirstPercent() {
      if (this.academicStatistics.article_total === 0) return 0;
      return `${Math.round(
        (this.academicStatistics.first_total / this.academicStatistics.article_total) * 10000,
      ) / 100}%`;
    },
    // 核心期刊比例
    getCorePeriodical() {
      if (this.academicStatistics.periodical_total === 0) return 0;
      return `${Math.round(
        (this.academicStatistics.core_periodical / this.academicStatistics.periodical_total) *
          10000,
      ) / 100}%`;
    },
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
  },
  created() {
    this.getProfessionalTitle();
    this.getAcademicTitle();
  },
  mounted() {
    this.getInfo();
    this.initFilter();
    this.init();
    window.onresize = () => {
      return (() => {
        this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
        this.cuttingTags();
      })();
    };
  },
  methods: {
    // 切换期刊类型
    handleSelectType(i) {
      console.log(i);
      if (i !== this.selectIndex) {
        this.selectIndex = i;
        this.currentDataPage = 1;
        this.pageDataSize = 10;
        this.totalNum = 0;
        this.getAcademicStatics();
        this.getAcademicData();
      }
    },
    // 跳转到标签的详情页
    handleGoTag(details) {
      const query = {
        id: details.id,
      };
      const routerData = this.$router.resolve({
        path: '/dashboard/tag-manage-detail',
        query,
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    handleExpand(ellipsis) {
      this.ellipsis = ellipsis;
    },
    showLabel(code, list) {
      const item = this.options[list].filter(item => item.code === code);
      if (item.length) return item[0].label;
      return '-';
    },
    // 获取专业职称
    getProfessionalTitle() {
      GetDictOptionList({ dict_api_name: 'professional_title', dict_type: '1' }).then(res => {
        this.options.professionalTitleOptins = res.dict_list;
      });
    },
    // 获取学士头衔
    getAcademicTitle() {
      GetDictOptionList({ dict_api_name: 'academic_title', dict_type: '1' }).then(res => {
        this.options.academicTitleOptions = res.dict_list;
      });
    },
    // 跳转医生详情页
    handleToDetail(hcpName, hcpId, val) {
      console.log(hcpName, hcpId, val);
      if (!hcpId) return;
      if (this.systemEnv === '1' && val === 2) {
        const href = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: {
            id: hcpId ? hcpId : this.$route.query.id,
            name: hcpName ? hcpName : this.info.name,
            type: 'preview',
            source: 'doctor',
          },
        });
        window.open(href.href, '_blank');
      }
      if (val === 1) {
        const href = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: {
            id: hcpId ? hcpId : this.$route.query.id,
            name: hcpName ? hcpName : this.info.name,
            type: 'preview',
            source: 'doctor',
          },
        });
        window.open(href.href, '_blank');
      }
      // const href = this.$router.resolve({
      //   path: '/dashboard/data-detail',
      //   query: {
      //     id: hcpId ? hcpId : this.$route.query.id,
      //     name: hcpName ? hcpName : this.info.name,
      //     type: 'preview',
      //     source: 'doctor',
      //   },
      // });
      // window.open(href.href, '_blank');
    },
    // 获取基础信息
    getInfo() {
      // const req = {
      //   obj_name: 't_hcp',
      //   data_id: this.$route.query.id,
      //   fields: [
      //     'tag_value_ids',
      //     'name',
      //     'gender',
      //     'hco_name',
      //     'professional_title',
      //     'academic_title',
      //     'standard_dept_name1',
      //     'standard_dept_name2',
      //     'standard_dept_name3',
      //     'hcp_description',
      //   ],
      // };
      const req = {
        daas_code: this.$route.query.daas_code,
      };
      GetKolDetail(req).then(res => {
        console.log('医生详情', res);
        this.info = res;
        // this.info = JSON.parse(res.data_detail);
        if (this.info.standard_dept_name1 !== '') {
          this.standard_dept.push(this.info.standard_dept_name1);
        }
        if (this.info.standard_dept_name2 !== '') {
          this.standard_dept.push(this.info.standard_dept_name2);
        }
        if (this.info.standard_dept_name3 !== '') {
          this.standard_dept.push(this.info.standard_dept_name3);
        }
        this.tagDataOld = res.tags;
        this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
        if (this.tagDataOld.length) {
          this.cuttingTags();
        }

        // // this.info.hcp_description =
        // // '五百里滇池，奔来眼底，披襟岸帻，喜茫茫空阔无边。看东骧神骏，西翥灵仪，北走蜿蜒，南翔缟素。高人韵士，何妨选胜登临。趁蟹屿螺洲，梳裹就风鬟雾鬓；更苹天苇地，点缀些翠羽丹霞，莫辜负四围香稻，万顷晴沙，九夏芙蓉，三春杨柳。数千年往事，注到心头，把酒凌虚，叹滚滚英雄谁在。想汉习楼船，唐标铁柱，宋挥玉斧，元跨革囊。伟烈丰功，费尽移山心力。尽珠帘画栋，卷不及暮雨朝云；便断碣残碑，都付与苍烟落照。只赢得几杵疏钟，半江渔火，两行秋雁，一枕清霜。';
        // if (this.info.tag_value_ids !== '' && this.info.tag_value_ids) {
        //   // 获取标签
        //   const req = {
        //     tag_value_ids: this.info.tag_value_ids.split(','),
        //   };
        //   GetTagInfosByValues(req)
        //     .then((res) => {
        //       console.log('所有标签', res);
        //       this.tagDataOld = res.tags.filter((item) => {
        //         return item.attribute === '衍生';
        //       });
        //       this.tagData = JSON.parse(JSON.stringify(this.tagDataOld));
        //       if (this.tagDataOld.length) {
        //         this.cuttingTags();
        //       }
        //     })
        //     .catch((err) => {
        //       this.$message.error(err.message);
        //     });
        // }
      });
    },
    init() {
      this.handleClickTab();
    },
    restoreTags() {
      this.tagData = this.tagDataOld;
      console.log(this.tagData);
      this.showBtnZK = false;
    },
    cuttingTags() {
      let idx = 0;
      let count = 0;
      let iIndex0 = 0;
      this.$nextTick(() => {
        // 获取所有标签节点
        const childTags = document.querySelector('#info-tags').childNodes;
        childTags?.forEach((item, index) => {
          // 把第一个标签的左偏移保存，且如果第一个没有值，取下一个
          iIndex0 = iIndex0 ? iIndex0 : item.offsetLeft;
          // 判断有几个标签在最左边，iIndex0判断第一个要不要作为标准来判断
          if (iIndex0 && item.offsetLeft === iIndex0) {
            count += 1;
            if (count === 3) {
              idx = index - 1;
              this.showDivZK = true;
              this.showBtnZK = true;
              this.show = true;
            }
          }
        });
        // 如果idx有值了，就对数组进行切割
        if (idx > 0) {
          this.tagData = [...new Set(this.tagDataOld)].slice(0, idx);
        } else {
          this.showDivZK = false;
          this.showBtnZK = false;
          this.show = false;
        }
      });
    },

    // 返回
    handleBack() {
      //   this.g_tourl('/dashboard/doctor-image');
      this.$router.go(-1);
    },

    // 切换tab
    handleClickTab(tab) {
      this.activeName = tab?.name ? tab.name : 'relation_network';
      this.currentDataPage = 1;
      this.pageDataSize = 10;
      this.requestData = true;
      this.isQeqTable = true;
      this.tableData = [];
      this.filterInit();

      switch (this.activeName) {
        case 'relation_network':
          this.configList = relationNetConfig;
          this.getRelationNetData();
          break;
        case 'influence':
          this.configList = influenceConfig;
          this.getInfluenceData();
          break;
        case 'academic_trend':
          this.configList = academicConfig;
          this.dateTabIndex = 0;
          this.academicType = '0';
          this.getAcademicStatics();
          this.getAcademicData();
          break;
      }
    },

    // 获取关系网络图表数据
    getRelationNetData(isInit = true) {
      if (isInit) this.getAllRelation();
      this.isQeqTable = true;

      const { relationship } = this.filterObject;
      const tableParams = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        rel_type: relationship?.label ? [relationship.label] : [],
        index: this.currentDataPage,
        page_size: this.pageDataSize,
      };
      SearchHcpSocialRelations(tableParams)
        .then(res => {
          if (res?.relation) {
            res.relation.forEach((ele, index) => {
              ele.id = `relation-${index}`;
            });
            this.tableData = res.relation;
            this.totalNum = res.total;
            setTimeout(() => {
              this.requestData = false;
              this.isQeqTable = false;
            }, 500);
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
          this.isQeqTable = false;
        });
    },

    getAllRelation() {
      const chartParams = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        rel_type: [],
        index: 0,
        page_size: 0,
      };
      SearchHcpSocialRelations(chartParams)
        .then(res => {
          if (res?.relation) {
            // 3层同心圆的数据比例为1:3:6
            // 初步方案：超过80条关系，取前80条
            const highIdx = res.relation.length > 80 ? 80 * 0.1 : res.relation.length * 0.1;
            const middleIdx = res.relation.length > 80 ? 80 * 0.3 : res.relation.length * 0.3;
            const lowIdx = res.relation.length > 80 ? 80 * 0.6 : res.relation.length * 0.6;
            console.log(highIdx, middleIdx, lowIdx);

            const highList = res.relation.slice(0, highIdx);
            const middleList = res.relation.slice(highIdx, highIdx + middleIdx);
            const lowList = res.relation.slice(highIdx + middleIdx, highIdx + middleIdx + lowIdx);

            const high = [];
            const middle = [];
            const low = [];
            highList.forEach(ele => {
              high.push({
                name: ele.hcp_name,
                relation: this.getRelationLabel(ele.relationship),
              });
            });
            middleList.forEach(ele => {
              middle.push({
                name: ele.hcp_name,
                relation: this.getRelationLabel(ele.relationship),
              });
            });
            lowList.forEach(ele => {
              low.push({
                name: ele.hcp_name,
                relation: this.getRelationLabel(ele.relationship),
              });
            });
            // console.log(high, middle, low);
            this.relationChartData = {
              high,
              middle,
              low,
            };
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
        });
    },

    getRelationLabel(val) {
      switch (val) {
        case '老师':
          return 'teacher';
        case '（师从）老师':
          return 'teacher';
        case '学生':
          return 'student';
        case '（辅导）学生':
          return 'student';
        case '同事':
          return 'fellow';
        case '同学':
          return 'classmate';
        case '合作':
          return 'coperation';
      }
    },

    // 获取影响力图表数据
    getInfluenceData() {
      this.requestData = true;
      SearchHcpInfluence({ daas_code: this.$route.query.daas_code })
        .then(res => {
          if (res) {
            this.influenceScore = {
              score: res.score.toFixed(1),
              kol_score: res.kol_score.toFixed(1),
              industry_score: res.industry_score.toFixed(1),
            };
            const chartIndicator = [];
            const scoreData = [];
            const kolScoreData = [];
            const industryScoreData = [];
            res.influence_data.forEach((ele, index) => {
              ele.id = `influence-${index}`;
              chartIndicator.push({
                text: ele.influence_name,
              });
              if (String(ele.score).indexOf('.') > -1) {
                ele.score = ele.score.toFixed(1);
              }
              if (String(ele.kol_score).indexOf('.') > -1) {
                ele.kol_score = ele.kol_score.toFixed(1);
              }
              if (String(ele.industry_score).indexOf('.') > -1) {
                ele.industry_score = ele.industry_score.toFixed(1);
              }
              scoreData.push(ele.score);
              kolScoreData.push(ele.kol_score);
              industryScoreData.push(ele.industry_score);
            });
            this.tableData = res.influence_data;

            this.$nextTick(() => {
              this.influenceChart = this.$echarts.init(document.getElementById('influence-chart'));
              // 页面大小调整变化，重新计算图表
              window.onresize = () => {
                this.influenceChart.resize();
              };
              const influenceOption = {
                title: {
                  text: '',
                },
                tooltip: {
                  trigger: 'axis',
                },
                legend: {
                  left: 'left',
                  data: ['HCP得分', 'KOL平均分', '行业平均分'],
                  itemWidth: 32,
                  itemHeight: 2,
                  itemGap: 32,
                  textStyle: {
                    fontWeight: 400,
                    fontFamily: 'PingFang SC',
                    fontSize: 14,
                    lineHeight: 22,
                    color: '#4D5762',
                  },
                },
                radar: [
                  {
                    indicator: chartIndicator,
                    center: ['50%', '55%'],
                    radius: 200,
                    startAngle: 90,
                    splitNumber: 5,
                    shape: 'circle',
                    name: {
                      formatter: '{value}',
                      textStyle: {
                        color: '#4D5762',
                        fontSize: 14,
                        lineHeight: 22,
                        fontFamily: 'PingFang SC',
                        fontWeight: 400,
                      },
                    },
                    nameGap: 12,
                    splitArea: {
                      areaStyle: {
                        color: [
                          'rgba(255, 255, 255)',
                          'rgba(255, 255, 255)',
                          'rgba(255, 255, 255)',
                          'rgba(255, 255, 255)',
                          'rgba(255, 255, 255)',
                        ],
                      },
                    },
                    axisLine: {
                      lineStyle: {
                        color: 'rgba(229,234,242)',
                      },
                    },
                    splitLine: {
                      lineStyle: {
                        color: 'rgba(229,234,242)',
                      },
                    },
                    // 坐标刻度值
                    axisLabel: {
                      show: false,
                      color: '#333',
                    },
                    // triggerEvent: true,
                  },
                ],
                series: [
                  {
                    name: '雷达图',
                    type: 'radar',
                    data: [
                      // data第一个值是控制坐标刻度标准
                      {
                        value: [5, 5, 5, 5],
                        name: '满分',
                        symbol: 'none',
                        itemStyle: {
                          color: '#fff',
                        },
                      },
                      {
                        value: scoreData,
                        name: 'HCP得分',
                        symbol: 'none',
                        // areaStyle: {
                        //   color: 'rgba(0, 105, 250, 0.05)',
                        // },
                        itemStyle: {
                          color: '#0069FA',
                        },
                        tooltip: {
                          trigger: 'item',
                        },
                        // z: 2,
                      },
                      {
                        value: kolScoreData,
                        name: 'KOL平均分',
                        symbol: 'none',
                        // areaStyle: {
                        //   color: 'rgba(0, 105, 250, 0.05)',
                        // },
                        itemStyle: {
                          color: '#FFB300',
                        },
                        tooltip: {
                          trigger: 'item',
                        },
                        // z: 5,
                      },
                      {
                        value: industryScoreData,
                        name: '行业平均分',
                        symbol: 'none',
                        // areaStyle: {
                        //   color: 'rgba(4, 204, 201, 0.05)',
                        // },
                        itemStyle: {
                          color: '#04CCC9',
                        },
                        tooltip: {
                          trigger: 'item',
                        },
                        // z: 10,
                      },
                    ],
                  },
                ],
              };
              this.influenceChart.setOption(influenceOption);
              // 这里点击4个影响因子、图层内部都会调用click事件，这里不好做...
              this.influenceChart.on('click', event => {
                console.log(event);
              });
            });

            setTimeout(() => {
              this.requestData = false;
            }, 500);
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
        });
    },

    // 获取学术动态统计数据
    getAcademicStatics() {
      const params = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        article_type: '', // 文章类型,1-期刊,2-会议,3-报纸与其他
        begin_time: '',
        end_time: '',
        article_name: '',
        is_first: '', // 是否第一作者,1-是,2-否
        index: 0,
        page_size: 0,
        search_type: 1, // 查询类型,1-查统计数据,2-查文章列表
        time_order: 0,
        language: this.selectIndex + 1,
      };
      SearchHcpArticle(params).then(res => {
        if (res) {
          const statistics = res.data_statistics;
          this.academicStatistics = {
            article_total: statistics.article_total,
            first_total: statistics.first_total,
            periodical_total: statistics.periodical_total,
            core_periodical: statistics.core_periodical,
            meeting_total: statistics.meeting_total,
            other_total: statistics.other_total,
          };
        }
      });
    },

    // 获取学术动态表格数据
    getAcademicData(reqChart = true, isChoose = false) {
      if (isChoose) {
        this.isQeqTable2 = true;
      } else {
        this.isQeqTable = true;
      }
      const { beginTime, endTime } = this.getRecentDate();
      const { title, author_index: authorIdx, periodical_type: periodicalType } = this.filterObject;
      const params = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        article_type: this.academicType === '0' ? '' : this.academicType, // 文章类型,1-期刊,2-会议,3-报纸与其他
        begin_time: this.dateTabIndex === 0 ? '' : beginTime,
        end_time: this.dateTabIndex === 0 ? '' : endTime,
        article_name: title?.value ? title.value : '',
        is_first: authorIdx?.value ? authorIdx?.value : '', // 是否第一作者,1-是,2-否
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_type: 2, // 查询类型,1-查统计数据,2-查文章列表
        time_order: 0,
        language: this.selectIndex + 1,
        is_kernel: periodicalType?.value ? periodicalType?.value : 0,
      };
      SearchHcpArticle(params)
        .then(res => {
          if (res) {
            const dataList = res.data_list.map(item => JSON.parse(item));
            dataList.forEach((ele, index) => {
              ele.id = `academic-${index}`;
            });
            this.tableData = dataList.map(item => {
              if (item.source_type === 'PubMed') {
                return { ...item, source: item.source_en };
              }
              return item;
            });
            this.totalNum = res.total;

            if (isChoose) {
              this.isQeqTable2 = false;
            } else {
              if (reqChart) {
                this.isQeqTable2 = false;
                this.getAcademicChart();
              } else {
                setTimeout(() => {
                  this.requestData = false;
                  this.isQeqTable = false;
                }, 500);
              }
            }
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
          this.isQeqTable = false;
          this.isQeqTable2 = false;
        });
    },

    // 获取学术动态图表数据
    getAcademicChart() {
      const params1 = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        article_type: this.academicType === '0' ? '' : this.academicType, // 文章类型,1-期刊,2-会议,3-报纸和其他
        search_range: String(this.dateTabIndex + 1), // 搜索范围 1-全部,2-近3年,3-近5年
        is_first: '',
        language: this.selectIndex + 1,
      };

      const params2 = {
        // hcp_id: this.$route.query.id,
        daas_code: this.$route.query.daas_code,
        article_type: this.academicType === '0' ? '' : this.academicType,
        search_range: String(this.dateTabIndex + 1),
        is_first: '1',
        language: this.selectIndex + 1,
      };
      const allAuthorReq = new Promise(resolve => {
        SearchArticleYears(params1).then(res => {
          resolve(res.data);
        });
      });
      const firstAuthorReq = new Promise(resolve => {
        SearchArticleYears(params2).then(res => {
          resolve(res.data);
        });
      });
      Promise.all([allAuthorReq, firstAuthorReq])
        .then(res => {
          if (res) {
            // 第一作者没有发表文章的年份置为0
            // const getZero = getZeroArr(res[0], res[1], 'year');
            // const arr = [];
            // getZero.forEach(ele => {
            //   arr.push({
            //     year: ele.year,
            //     total: 0,
            //   });
            // });

            // const allFirstAuthor = [];
            // allFirstAuthor.push(...res[1], ...arr);
            // allFirstAuthor.sort((a, b) => {
            //   return a.year - b.year;
            // });
            res[0].sort((a, b) => {
              return a.year - b.year;
            });
            res[1].sort((a, b) => {
              return a.year - b.year;
            });

            const allYear = [];
            const allArticle = [];
            res[0].forEach(ele => {
              allYear.push(ele.year);
              allArticle.push(ele.total);
            });
            const firstYear = [];
            const firstArticle = [];
            res[1].forEach(ele => {
              firstYear.push(ele.year);
              firstArticle.push(ele.total);
            });

            this.requestData = false;
            this.isQeqTable = false;
            const chartData = { all: { allYear, allArticle }, first: { firstYear, firstArticle } };
            this.$nextTick(() => {
              this.getAllAcademic(chartData);
            });
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
          this.isQeqTable = false;
        });
    },

    getAllAcademic(chartData) {
      console.log(chartData);
      let initYear = [];
      let initArticle = [];
      if (chartData.all.allArticle.length === 0) {
        initYear = ['2005'];
        initArticle = [0];
      }
      this.academicChart = this.$echarts.init(document.getElementById('academic-chart'));
      // 页面大小调整变化，重新计算图表
      window.onresize = () => {
        this.academicChart.resize();
      };
      const academicOption = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['全部', '第一作者'],
          right: '1%',
          itemGap: 24,
          itemHeight: 8,
          textStyle: {
            fontWeight: 400,
            fontFamily: 'PingFang SC',
            fontSize: 12,
            lineHeight: 20,
            color: '#667082',
          },
        },
        grid: {
          left: '1%',
          right: '2%',
          bottom: '1%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: chartData.all.allArticle.length === 0 ? initYear : chartData.all.allYear,
          axisLabel: {
            interval: 4,
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '全部',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: chartData.all.allArticle.length === 0 ? initArticle : chartData.all.allArticle,
            areaStyle: {
              opacity: 0.9,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0, 105, 250, 0.1)',
                },
                {
                  offset: 1,
                  color: 'rgba(0, 105, 250, 0.0001)',
                },
              ]),
            },
            itemStyle: {
              color: '#0069FA',
            },
          },
          {
            name: '第一作者',
            type: 'line',
            smooth: true,
            showSymbol: false,
            data:
              chartData.all.allArticle.length === 0 ? initArticle : chartData.first.firstArticle,
            areaStyle: {
              opacity: 0.9,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(4, 204, 201, 0.1)',
                },
                {
                  offset: 1,
                  color: 'rgba(4, 204, 201, 0.0001)',
                },
              ]),
            },
            itemStyle: {
              color: '#04CCC9',
            },
          },
        ],
      };
      this.academicChart.setOption(academicOption);
    },

    getRecentDate() {
      let beginTime = '';
      let endTime = '';
      if (this.dateTabIndex === 1) {
        const { year, month, date } = getDate(new Date());
        beginTime = `${year - 3}-${month}-${date}`;
        endTime = `${year}-${month}-${date}`;
      } else if (this.dateTabIndex === 2) {
        const { year, month, date } = getDate(new Date());
        beginTime = `${year - 5}-${month}-${date}`;
        endTime = `${year}-${month}-${date}`;
      }
      return { beginTime, endTime };
    },

    // 选择期刊类型
    changeAcademicType() {
      console.log(this.academicType);
      this.getAcademicData();
    },

    // 选择发表的时间
    handleDateChange(idx) {
      console.log(idx);
      this.dateTabIndex = idx;
      (this.currentDataPage = 1), (this.pageDataSize = 10), (this.totalNum = 0);
      this.getAcademicData();
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage() || {};
      this.updateFilterList();
    },

    // 搜索表头关键字
    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      this.currentDataPage = 1;
      this.totalNum = 0;
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        if (property === 'relationship') {
          if (val === 'student') label = '（辅导）学生';
          if (val === 'teacher') label = '（师从）老师';
          this.filterChange({ [property]: { label, value: val, formType } });
        } else {
          this.filterChange({ [property]: { label, value: val, formType } });
        }
      }
    },

    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.selectTabOpt();
    },

    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (JSON.stringify(this.filterObject[key]) !== '{}') {
          list.push({ name: key, label: this.filterObject[key].label });
        }
      });
      this.filterList = list;
      this.setStorage();
    },

    // 展示筛选条件的标题
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    setStorage() {
      const filterData = this.getStorage();
      if (this.activeName === 'relation_network') {
        filterData['doctorImg-relationNet'] = this.filterObject;
        localStorage.setItem('doctorImg-relationNet-filter', JSON.stringify(this.filterObject));
      } else {
        filterData['doctorImg-Academic'] = this.filterObject;
        localStorage.setItem('doctorImg-Academic-filter', JSON.stringify(this.filterObject));
      }
    },
    getStorage() {
      let str = null;
      if (this.activeName === 'relation_network') {
        str = localStorage.getItem('doctorImg-relationNet-filter');
      } else {
        str = localStorage.getItem('doctorImg-Academic-filter');
      }
      return str ? JSON.parse(str) : {};
    },

    // 删除关键词
    handleDelKeyWords(key) {
      this.currentDataPage = 1;
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.selectTabOpt();
    },

    deleteAllFilter() {
      this.currentDataPage = 1;
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      if (this.activeName === 'relation_network') {
        this.getRelationNetData(false);
        localStorage.removeItem('doctorImg-relationNet-filter');
      } else {
        this.getAcademicData(false, true);
        localStorage.removeItem('doctorImg-Academic-filter');
      }
    },

    // 刷新浏览器，重置所有筛选项
    initFilter() {
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      localStorage.removeItem('doctorImg-relationNet-filter');
      localStorage.removeItem('doctorImg-Academic-filter');
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.selectTabOpt();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.selectTabOpt();
    },

    selectTabOpt() {
      switch (this.activeName) {
        case 'relation_network':
          this.getRelationNetData(false);
          break;
        case 'influence':
          this.getInfluenceData();
          break;
        case 'academic_trend':
          this.getAcademicData(false, true);
          break;
      }
    },

    formatOblique(val) {
      return val.replace(/;/g, '/');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
