export default [
  {
    title: '姓名',
    width: 250,
    property: 'hcp_name',
  },
  {
    title: '关系',
    width: 250,
    property: 'relationship',
    showIcon: 'select',
  },
];
