export default [
  {
    title: '影响方向',
    width: 120,
    property: 'influence_name',
  },
  {
    title: '得分',
    width: 120,
    property: 'score',
  },
  {
    title: 'KOL平均分',
    width: 120,
    property: 'kol_score',
  },
  {
    title: '行业平均分',
    width: 120,
    property: 'industry_score',
  },
];
