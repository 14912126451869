export default [
  {
    title: '文章名称',
    'min-width': 300,
    property: 'title',
    showIcon: 'input',
    sort: false,
  },
  {
    title: '期刊名称',
    width: 200,
    property: 'source',
    sort: false,
  },
  {
    title: '文章类型',
    width: 150,
    property: 'publication_type',
    sort: false,
  },
  {
    title: '是否核心',
    width: 150,
    property: 'periodical_type',
    showIcon: 'select',
    sort: false,
  },
  {
    title: '是否一作',
    width: 150,
    property: 'author_index',
    showIcon: 'select',
    sort: false,
  },
  {
    title: '作者',
    width: 200,
    property: 'authors',
    sort: false,
  },
  {
    title: '发表时间',
    width: 150,
    property: 'publish_time',
    format: true,
    sort: true,
  },
];
